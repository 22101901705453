<template>
  <v-form>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-toolbar-title>Item Master Data</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="flatClose"
          icon
          to="/inventory/item-master-data"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- end of toolbar 1 -->

      <!-- start of toolbar 2 -->
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-btn
          class="mr-1"
          outlined
          color="toolbarIcon"
          text
          @click="sendData"
          :loading="loader"
        >
          <v-icon
            left
            dark
          >mdi-content-save</v-icon>Submit
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-1"
          outlined
          color="toolbarIcon"
          text
          to="/inventory/item-master-data"
        >
          <v-icon
            left
            dark
          >mdi-eye</v-icon>All Items
        </v-btn>
      </v-toolbar>
      <!-- end of toolbar 2 -->

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <!-- toolbar 2 -->

            <!-- end -->
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              :filled="pageMode == 'view'"
              :readonly="pageMode == 'view'"
              dense
              outlined
              v-model="record.Series"
              :items="seriesData"
              label="Num Series"
              item-text="SeriesName"
              item-value="id"
              @change="setDocNumber(Series)"
            ></v-autocomplete>
          </v-col>

          <v-col cols="2">
            <v-text-field
              :filled="manualNum ? false : true"
              :readonly="manualNum ? false : true"
              outlined
              dense
              label="Item No"
              v-model="record.ItemCode"
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              outlined
              dense
              label="Description"
              v-model="record.ItemName"
            ></v-text-field>
          </v-col>

          <v-col cols="2">
            <v-autocomplete
              outlined
              dense
              v-model="record.ItemType"
              :items="itemTypes"
              item-text="name"
              item-value="value"
              label="Item Type"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              v-model="record.InvntItem"
              label="Inventory Item"
              value="Y"
            ></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              dense
              outlined
              v-model="record.DfltsGroup"
              :items="itemDefaults"
              item-text="Code"
              item-value="id"
              label="Item Defaults"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              outlined
              dense
              v-model="record.ItmsGrpCod"
              :items="itemGroups"
              item-text="ItmsGrpNam"
              item-value="id"
              label="Item Group"
            ></v-autocomplete>
          </v-col>

          <v-col cols="3">
            <v-autocomplete
              outlined
              dense
              v-model="record.UgpEntry"
              @change="CheckUoMGroup(record.UgpEntry)"
              :items="uomGroups"
              item-text="UgpName"
              item-value="id"
              label="UoM Group"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              v-model="record.SellItem"
              label="Sales Item"
              value="Y"
            ></v-checkbox>
          </v-col>

          <v-col cols="3">
            <v-autocomplete
              outlined
              dense
              v-model="record.PriceList"
              @change="setPriceList(record.PriceList)"
              :items="priceList"
              item-text="ListName"
              item-value="id"
              label="Price List"
            ></v-autocomplete>
          </v-col>
          <v-col cols="1">
            <v-text-field
              dense
              v-if="selectedPriceList.PrimCurr"
              v-model="selectedPriceList.PrimCurr"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              outlined
              dense
              label="Unit Price"
              v-model="record.Price"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-checkbox
              v-model="record.PrchseItem"
              label="Purchase Item"
              value="Y"
            ></v-checkbox>
          </v-col>

          <v-col
            cols="4"
            v-if="showPricing"
          >
            <v-autocomplete
              outlined
              dense
              @change="setSVolume(PriceUnit)"
              v-model="PriceUnit"
              :items="uoms"
              item-text="uomentry.UomName"
              item-value="uomentry.id"
              label="Pricing Unit"
            ></v-autocomplete>
          </v-col>

          <v-row>

          </v-row>

          <!-- tabs start here -->
          <v-tabs
            background-color="accent"
            color="white"
          >
            <v-tab>General</v-tab>
            <v-tab>Purchasing Data</v-tab>
            <v-tab>Sales Data</v-tab>
            <v-tab>Inventory Data</v-tab>
            <v-tab>Properties</v-tab>
            <!-- v-tab-items -->

            <!-- general -->
            <v-tab-item>
              <v-row
                dense
                class="pt-3"
              >
                <v-col cols="4">
                  <v-autocomplete
                    outlined
                    dense
                    @change="manageItem"
                    v-model="itemOption"
                    :items="itemOptions"
                    item-text="name"
                    item-value="value"
                    label="Manage By Item"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="4"
                  v-if="distributionRules1.length > 0"
                >
                  <v-autocomplete
                    outlined
                    dense
                    v-model="record.CogsOcrCodMthd"
                    :items="dmMethods"
                    item-text="name"
                    item-value="value"
                    :label="Dimension1"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="4"
                  v-if="distributionRules2.length > 0"
                >
                  <v-autocomplete
                    outlined
                    dense
                    v-model="record.CogsOcrCo2Mthd"
                    :items="dmMethods"
                    item-text="name"
                    item-value="value"
                    :label="Dimension2"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="4"
                  v-if="distributionRules3.length > 0"
                >
                  <v-autocomplete
                    outlined
                    dense
                    v-model="record.CogsOcrCo3Mthd"
                    :items="dmMethods"
                    item-text="name"
                    item-value="value"
                    :label="Dimension3"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="4"
                  v-if="distributionRules4.length > 0"
                >
                  <v-autocomplete
                    outlined
                    dense
                    v-model="record.CogsOcrCo4Mthd"
                    :items="dmMethods"
                    item-text="name"
                    item-value="value"
                    :label="Dimension4"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="4"
                  v-if="distributionRules5.length > 0"
                >
                  <v-autocomplete
                    outlined
                    dense
                    v-model="record.CogsOcrCo5Mthd"
                    :items="dmMethods"
                    item-text="name"
                    item-value="value"
                    :label="Dimension5"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- end of general -->

            <!-- start of purchasing data -->
            <v-tab-item>
              <v-row
                dense
                class="pt-3"
              >
                <v-col cols="12">
                  <v-row dense>
                    <v-col cols="3">
                      <v-autocomplete
                        dense
                        outlined
                        v-model="record.CardCode"
                        :items="bpMasterData"
                        item-text="CardName"
                        item-value="id"
                        label="Preffered Vendor"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="3">
                      <v-autocomplete
                        dense
                        outlined
                        v-model="record.VatGroupPu"
                        :items="inputTaxes"
                        item-text="name"
                        item-value="code"
                        label="Tax Group"
                      ></v-autocomplete>
                    </v-col>

                    <v-col
                      cols="3"
                      v-show="manualStatus == false"
                    >
                      <v-autocomplete
                        outlined
                        dense
                        v-model="record.PUoMEntry"
                        @change="setBuyUnitMsr(record.PUoMEntry)"
                        :items="uoms"
                        item-text="uomentry.UomName"
                        item-value="uomentry.id"
                        label="Purchasing UOM Code"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="3">
                      <v-text-field
                        :readonly="manualStatus ? false : true"
                        :filled="manualStatus ? false : true"
                        dense
                        outlined
                        label="Purchasing UOM Name"
                        v-model="record.BuyUnitMsr"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="3">
                      <v-text-field
                        :readonly="manualStatus ? false : true"
                        :filled="manualStatus ? false : true"
                        dense
                        outlined
                        label="Items Per Sales Unit"
                        v-model="record.NumInBuy"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="3">
                      <v-text-field
                        dense
                        outlined
                        label="Package Type"
                        v-model="PackageType"
                        :value="oneValue"
                        filled
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col cols="3">
                      <v-text-field
                        outlined
                        dense
                        color="accent"
                        label="Qty Per Package"
                        v-model="PurPackUn"
                        filled
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- end of purchasing data -->

            <!-- start of sales data -->
            <v-tab-item>
              <v-row
                dense
                class="pt-3"
              >
                <v-col cols="12">
                  <v-row>
                    <v-col cols="3">
                      <v-autocomplete
                        dense
                        outlined
                        v-model="record.VatGourpSa"
                        :items="outputTaxes"
                        item-text="name"
                        item-value="code"
                        label="Tax Group"
                      ></v-autocomplete>
                    </v-col>

                    <v-col
                      cols="3"
                      v-show="manualStatus == false"
                    >
                      <v-autocomplete
                        dense
                        outlined
                        v-model="record.SUoMEntry"
                        @change="setSalUnitMsr(record.SUoMEntry)"
                        :items="uoms"
                        item-text="uomentry.UomName"
                        item-value="uomentry.id"
                        label="Sales UOM Code"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="3">
                      <v-text-field
                        :readonly="manualStatus ? false : true"
                        :filled="manualStatus ? false : true"
                        dense
                        outlined
                        label="Sales UOM Name"
                        v-model="record.SalUnitMsr"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="3">
                      <v-text-field
                        :readonly="manualStatus ? false : true"
                        :filled="manualStatus ? false : true"
                        dense
                        outlined
                        label="Items Per Sales Unit"
                        v-model="record.NumInSale"
                      ></v-text-field>
                    </v-col>

                    <!-- <v-col cols="3">
                  <v-text-field dense outlined label="Sales Unit" v-model="record.SVolume"></v-text-field>
                    </v-col>-->
                  </v-row>
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- end of sales data -->

            <!-- start of inventory data -->
            <v-tab-item>
              <v-row
                dense
                class="pt-3"
              >
                <v-col cols="12">
                  <v-row dense>
                    <v-col cols="4">
                      <!-- top level uom -->
                      <v-autocomplete
                        outlined
                        dense
                        v-model="record.IUoMEntry"
                        @change="setInvntryUom(record.IUoMEntry)"
                        :items="uoms"
                        item-text="uomentry.UomName"
                        item-value="uomentry.id"
                        label="UOM Code"
                      ></v-autocomplete>

                      <v-text-field
                        dense
                        outlined
                        label="UOM Name"
                        v-model="record.InvntryUom"
                      ></v-text-field>
                      <!-- end -->
                    </v-col>

                    <v-col cols="4">
                      <v-autocomplete
                        dense
                        outlined
                        v-model="record.GLMethod"
                        :items="invMethods"
                        item-text="name"
                        item-value="value"
                        label="Set Inv. Method By"
                      ></v-autocomplete>

                      <v-autocomplete
                        dense
                        outlined
                        v-model="record.EvalSystem"
                        :items="valMethods"
                        item-text="name"
                        item-value="value"
                        label="Valuation Method"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <!-- end of row 1 -->
                  <v-row>
                    <v-col cosl="12">
                      <v-divider></v-divider>
                    </v-col>

                    <v-col cols="12">
                      <v-data-table
                        calculate-widths
                        hide-default-footer
                        :headers="owhsHeaders"
                        :items="pageMode == 'create' ? warehouses: record.oitw"
                        :search="search"
                        show-select
                        single-select
                      >
                        <template
                          v-slot:item.remove="{ item }"
                          v-if="pageMode == 'create'"
                        >
                          <v-btn
                            icon
                            @click="removeWarehouse(item.id)"
                          >
                            <v-icon color="red">mdi-close</v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                      <v-btn
                        v-if="pageMode == 'create'"
                        color="accent"
                        text
                        right
                        absolute
                      >
                        <v-icon left>mdi-check</v-icon>Set Default Whse
                      </v-btn>
                    </v-col>

                    <v-col cols="12"></v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- end of inventory data -->

            <!-- start of properties -->
            <v-tab-item>
              <v-row
                dense
                class="pt-3"
              >
                <v-col cols="4">
                  <v-data-table
                    :headers="itemHeaders"
                    :items="record.itm15"
                    item-key="id"
                  >
                    <!-- start of start time template -->
                    <template v-slot:item.QryGroup="{ item }">
                      <v-autocomplete
                        outlined
                        dense
                        v-model="item.QryGroup"
                        :items="item.itg1"
                        item-text="GrpName"
                        item-value="id"
                      ></v-autocomplete>
                    </template>
                    <!-- end of start time -->
                  </v-data-table>
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- end -->
          </v-tabs>
        </v-row>

        <!-- snackbar -->
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-form>
</template>


<script>
// import generalEditorVue from '../../../administration/system-init/company-details/_components/general-editor.vue';
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
    currencies: {
      type: Array,
    },
    pageMode: {
      type: String,
    },
  },
  data: () => ({
    search: null,
    loader: false,
    seriesData: [],
    itg1: [],
    manualNum: false,
    userDefaultItmGrp: null,
    genSettingsItmGrp: null,
    record: {},
    oneValue: 1,
    showPricing: false,
    manualStatus: false,
    warehouses: [],
    tab: null,
    PriceUnit: null,
    PurPackUn: 1,
    PackageType: 1,
    priceList: [],
    selectedPriceList: {},
    purchasingUomCode: null,
    salesEmployees: [],
    uomsData: [],
    itemGroups: [],
    taxGroups: [],
    inputTaxes: [],
    outputTaxes: [],
    uomGroups: [],
    bpMasterData: [],
    itemProperties: [],
    uoms: [],
    itemDefaults: [],
    owhsHeaders: [
      { text: "Whs Code", value: "WhsCode" },
      { text: "Whs Name", value: "WhsName" },
      { text: "In Stock", value: "OnHand" },
      { text: "Commited", value: "IsCommited" },
      { text: "Ordered", value: "OnOrder" },
      { text: "Available", value: "available" },
      { text: "Item Cost", value: "account" },
      { text: "Action", value: "remove" },
    ],
    itemHeaders: [
      { text: "Name", value: "ItmsGrpNam" },
      { text: "Property", value: "QryGroup" },
    ],
    uomGroupsData: [
      { UgpName: "Manual", id: "manual" },
      { UgpName: "Months = Sqrft", id: "months" },
    ],
    series: [{ name: "Manual", value: "M" }],
    valMethods: [
      { name: "Moving Average", value: "A" },
      { name: "Serial/Batch", value: "B" },
      { name: "FIFO", value: "F" },
      { name: "Standard", value: "S" },
    ],
    invMethods: [
      { name: "Warehouse", value: "W" },
      { name: "Item Group", value: "C" },
      { name: "Item Level", value: "L" },
    ],
    dmMethods: [
      { name: "User Level", value: "U" },
      { name: "Item Level", value: "L" },
    ],
    itemOption: null,
    itemOptions: [
      { name: "None", value: "N" },
      { name: "Serial Numbers", value: "S" },
      { name: "Batches", value: "B" },
    ],
    itemTypes: [
      { name: "Fixed Assets", value: "F" },
      { name: "Items", value: "I" },
      { name: "Labour", value: "L" },
      { name: "Travel", value: "T" },
      { name: "Building", value: "B" },
    ],
    Dimension1: "",
    Dimension2: "",
    Dimension3: "",
    Dimension4: "",
    Dimension5: "",

    distributionRules1: [],
    distributionRules2: [],
    distributionRules3: [],
    distributionRules4: [],
    distributionRules5: [],
    groups: [],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
      if (val.itm1) {
        this.record = { ...this.record, Price: val.itm1.Price };
        this.record = { ...this.record, PriceList: val.itm1.PriceList };
      }
    },
    removeWarehouse(id) {
      const warehouse = this.warehouses.find((record) => {
        return record.id === id;
      });

      const pos = this.warehouses.indexOf(warehouse);
      if (pos === -1) {
        return;
      }
      this.warehouses.splice(pos, 1);
    },
    setDocNumber(id) {
      const seriesRecord = this.seriesData.find((record) => {
        return record.id === id;
      });

      if (seriesRecord.IsManual === "Y") {
        this.record = { ...this.record, ItemCode: null };
        this.manualNum = true;
      } else {
        this.manualNum = false;
        this.record = { ...this.record, ItemCode: seriesRecord.NextNumber };
      }
      this.record.Series = seriesRecord.id;
    },
    sendData() {
      if (this.record.ItemName == undefined) {
        this.$refs.snackbar.show("Please fill Item Description", "red");
      } else {
        // get globalWarehouses ids
        const warehouses = this.warehouses;
        let ids = warehouses.reduce((op, { id }) => {
          if (typeof id !== "undefined") {
            op.push(id);
          }
          return op;
        }, []);
        // end
        if (this.selectedPriceList != null) {
          this.record.Currency = this.selectedPriceList
            ? this.selectedPriceList.PrimCurr
            : null;
        }
        this.record.owhs = ids;
        // init loader
        this.loader = true;
        this.$emit("data", this.record);
      }
    },
    setDate(date) {
      this.record.date = date;
    },
    setBuyUnitMsr(id) {
      const data = this.uoms.find((record) => {
        return record.UomEntry === id;
      });
      this.record.BuyUnitMsr = data.uomentry.UomName;
      this.record.NumInBuy = data.BaseQty;
    },
    setSVolume(id) {
      const data = this.uoms.find((record) => {
        return record.UomEntry === id;
      });
      this.record.SVolume = data.BaseQty;
      this.record.PriceUnit = data.UomEntry;
    },
    setSalUnitMsr(id) {
      const data = this.uoms.find((record) => {
        return record.UomEntry === id;
      });
      this.record.SalUnitMsr = data.uomentry.UomName;
      this.record.NumInSale = data.BaseQty;
    },
    setInvntryUom(id) {
      const data = this.uoms.find((record) => {
        return record.UomEntry === id;
      });
      this.record.InvntryUom = data.uomentry.UomName;
      this.record.NumInCnt = data.BaseQty;
    },
    setPriceList(id) {
      const data = this.priceList.find((record) => {
        return record.id === id;
      });
      this.selectedPriceList = data;
    },
    manageItem(itemCode) {
      if (itemCode == "N") {
        this.record.ManBtchNum = "N";
        this.record.ManSerNum = "N";
      }
      if (itemCode == "S") {
        this.record.ManBtchNum = "N";
        this.record.ManSerNum = "Y";
      }
      if (itemCode == "B") {
        this.record.ManBtchNum = "Y";
        this.record.ManSerNum = "N";
      }
    },
    CheckUoMGroup(id) {
      const uomGroup = this.uomGroups.find((record) => {
        return record.id === id;
      });
      const self = this;
      if (uomGroup.UgpName === "Manual") {
        self.showPricing = false;
        self.manualStatus = true;
      } else {
        this.loading = true;
        this.$store
          .dispatch("get", `/uomgroup/${id}`)
          .then((res) => {
            self.uoms = res.ResponseData.ugp1;
            self.showPricing = true;
            self.manualStatus = false;
            self.record.SalUnitMsr = null;
            self.loading = false;
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
      }
    },
    getItemGroups() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/itemgroup`)
        .then((res) => {
          self.itemGroups = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getUomGroups() {
      const self = this;
      this.$store
        .dispatch("get", `/uomgroup`)
        .then((res) => {
          self.uomGroups = res.ResponseData.data;
          if (this.pageMode == "create") {
            // default manual uom group parameters
            //self.record = { ...self.record, UgpEntry: res.ResponseData[0].id };
            self.record = { ...self.record, UgpEntry: res.ResponseData.data[0].id };
            // set items per msr
            self.record.NumInBuy = 1;
            self.record.NumInSale = 1;
            self.record.NumInBuy = 1;
            // end
            self.showPricing = false;
            self.manualStatus = true;
            // end of manual defaults
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getPriceList() {
      const self = this;
      this.$store
        .dispatch("get", `/price_lists`)
        .then((res) => {
          self.priceList = res.ResponseData;
          if (this.pageMode == "create") {
            self.selectedPriceList = res.ResponseData[0];
            self.record = { ...self.record, PriceList: res.ResponseData[0].id };
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getBpMaster() {
      const self = this;
      self.loading = true;
      this.$store
        .dispatch("get", `/bp_masterdata`)
        .then((res) => {
          self.bpMasterData = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getInputTax() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/taxgroups/input`)
        .then((res) => {
          self.inputTaxes = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getOutputTax() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/taxgroups/output`)
        .then((res) => {
          self.outputTaxes = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getItemProperties() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/itemsproperty`)
        .then((res) => {
          if (self.pageMode == "create") {
            self.record.itm15 = res.ResponseData;
          }
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    formSettings() {
      const self = this;
      this.$store
        .dispatch("get", `/form_settings/3`)
        .then((res) => {
          self.seriesData = res.Series;
          if (self.pageMode == "create") {
            self.record.Series = res.DfltSeries.id;
            if (res.DfltSeries.IsManual == "Y") {
              self.manualNum = true;
            }
            self.record.ItemCode = res.DfltSeries.NextNumber;
          }
        })
        .catch((err) => {
          console.log(err, "error");
        });
    },
    setDefaults() {
      if (this.pageMode == "create") {
        this.record = {
          ...this.record,
          InvntItem: "Y",
          SellItem: "Y",
          PrchseItem: "Y",
        };
        // get user defaults
        const self = this;

        this.$store
          .dispatch("get", `/users/get-user-defaults`)
          .then(() => {
            // if (res.DftItmsGrpCod) {
            //   console.log(res.DftItmsGrpCod, "user res item");
            // }
          })
          .catch((err) => {
            console.log(err, "error");
          });
        // end

        // get default valuation method
        this.$store
          .dispatch("get", `/company_details`)
          .then((res) => {
            if (res.ResponseData.InvntSystm) {
              const valMethod = res.ResponseData.InvntSystm;
              self.record = { ...self.record, EvalSystem: valMethod };
            }
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
        // end of val method call

        // get Gl method/ Inv method default
        this.$store
          .dispatch("get", `/general_settings`)
          .then((res) => {
            if (res.ResponseData.DftItmsGrpCod !== null) {
              self.genSettingsItmGrp = res.ResponseData.DftItmsGrpCod;
            }

            if (res.ResponseData.GLMethod) {
              self.record = {
                ...self.record,
                GLMethod: res.ResponseData.GLMethod,
                ItmsGrpCod: res.ResponseData.DftItmsGrpCod,
              };
            }
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
        // end

        // set default tax groups
        this.$store
          .dispatch("get", `/gl_account_determination`)
          .then((res) => {
            // set sales tax
            if (res.ResponseData.DfSVatItem) {
              self.record = {
                ...self.record,
                VatGourpSa: res.ResponseData.DfSVatItem,
              };
            }
            // set purchasing tax
            if (res.ResponseData.DfPVatItem) {
              self.record = {
                ...self.record,
                VatGroupPu: res.ResponseData.DfPVatItem,
              };
            }
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
        // end

        // set default item group
        // console.log(self.genSettingsItmGrp, "general item");
        // console.log(self.userDefaultItmGrp, "user item");
        // if(userDefaults.DftItmsGrpCod !== null) {

        //     self.record = { ...self.record, ItmsGrpCod: userDefaults.DftItmsGrpCod };
        // } else if(generalSettings.DftItmsGrpCod !== null) {

        //     self.record = { ...self.record, ItmsGrpCod: generalSettings.DftItmsGrpCod };
        // }
        // end of item group default definition
        this.record = { ...this.record, ItemType: "F" };
        // set manage item by
        this.itemOption = "N";
        this.record.ManBtchNum = "N";
        this.record.ManSerNum = "N";
        this.record.DMMethod = "U";
      }
    },
    getWarehouses() {
      const self = this;
      this.$store
        .dispatch("get", `/warehouse`)
        .then((res) => {
          self.warehouses = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getDistributionRules(id) {
      const self = this;
      this.$store
        .dispatch("get", `/distribution-rules?DimCode=${id}`)
        .then((res) => {
          if (id == 1) {
            self.distributionRules1 = res.ResponseData;
            self.Dimension1 = "Set " + res.ResponseData[0].odim.DimDesc;
          }
          if (id == 2) {
            self.distributionRules2 = res.ResponseData;
            self.Dimension2 = "Set " + res.ResponseData[0].odim.DimDesc;
          }
          if (id == 3) {
            self.distributionRules3 = res.ResponseData;
            self.Dimension3 = "Set " + res.ResponseData[0].odim.DimDesc;
          }
          if (id == 4) {
            self.distributionRules4 = res.ResponseData;
            self.Dimension4 = "Set " + res.ResponseData[0].odim.DimDesc;
          }
          if (id == 5) {
            self.distributionRules5 = res.ResponseData;
            self.Dimension5 = "Set " + res.ResponseData[0].odim.DimDesc;
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$refs.snackbar.show(err, "red");
        });
    },

    getItemDefaults() {
      const self = this;
      this.$store
        .dispatch("get", `/item-defaults`)
        .then((res) => {
          self.itemDefaults = res.ResponseData;
        })
        .catch((err) => {
          console.log(err);
          // this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getWarehouses();
    this.getItemGroups();
    this.getUomGroups();
    this.getPriceList();
    this.getBpMaster();
    this.getInputTax();
    this.getOutputTax();
    this.getItemProperties();
    this.formSettings();
    this.setDefaults();
    this.getItemDefaults();

    this.getDistributionRules(1);
    this.getDistributionRules(2);
    this.getDistributionRules(3);
    this.getDistributionRules(4);
    this.getDistributionRules(5);
  },
};
</script>